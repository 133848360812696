@import url('https://fonts.googleapis.com/css?family=Inter:wght@400;700&display=swap');

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-image: url('/public/images/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	max-width: 500px;
	margin: 0 auto;
	min-height: 100vh;
}

@supports (-webkit-touch-callout: none) {
	.App {
		min-height: -webkit-fill-available !important;
	}
}

html {
	height: -webkit-fill-available;
}

body {
	background-image: url('/public/images/background.png');
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

.App-logo {
	height: 125px;
	pointer-events: none;
}

.brand-logo {
	width: 150px
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.mantine-Container-root {
	width: 992px;
}

form label,
.brand-title {
	color: white !important;
}

.brand-title {
	margin-bottom: 10px;
}

.launch-game-modal .mantine-Modal-modal {
	background-color: #6f2f76;
}

.btn.btn-default {
	background-color: white;
	color: #5f24ba;
}

.btn.btn-default:hover {
	background-color: #5f24ba;
	color: white;
	transition: all 0.3s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	background: linear-gradient(0deg, #8415C9, #400862);
	transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}